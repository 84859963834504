import { createSlice } from '@reduxjs/toolkit';
import { createEmptyTEObject } from '@timeedit/types';
import _ from 'lodash';
import { startAppListening } from 'slices/listenerMiddleware';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
import { isNewObject, NEW_OBJECT_ID } from 'utils/objectUtils';
export const initialState = {
    loading: false,
    hasErrors: false,
    allObjects: [],
    map: {},
    isFirstObjectSaveDisabled: true,
    isSecondObjectSaveDisabled: true,
    firstObject: undefined,
    secondObject: undefined,
    findObjectPayload: {}
};
// Slice
const slice = createSlice({
    name: 'objects',
    initialState,
    reducers: {
        fetchObjectsRequest: state => {
            isLoadingRequest(state);
        },
        fetchObjectsSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allObjects = payload.results;
            state.map = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchObjectsByIdsSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            payload.results.forEach(result => {
                state.map[result.id] = result;
            });
            finishedLoadingSuccess(state);
        },
        fetchMoreObjectSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.allObjects = [...state.allObjects, ...payload.results];
            payload?.results?.forEach(result => {
                state.map[result.id] = result;
            });
            finishedLoadingSuccess(state);
        },
        fetchObjectsFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteObjectRequest: state => {
            isLoadingRequest(state);
        },
        deleteObjectSuccess: (state, _ref4) => {
            let { payload: objectId } = _ref4;
            state.allObjects = state.allObjects.filter(object => object.id !== objectId);
            closeDrawerById(state, objectId);
            finishedLoadingSuccess(state);
        },
        deleteObjectFailure: state => {
            finishedLoadingFailure(state);
        },
        updateObjectRequest: state => {
            isLoadingRequest(state);
        },
        updateObjectSuccess: (state, _ref5) => {
            let { payload: objectId } = _ref5;
            closeDrawerById(state, objectId);
            finishedLoadingSuccess(state);
        },
        updateObjectFailure: state => {
            finishedLoadingFailure(state);
        },
        changeObject: (state, _ref6) => {
            let { payload } = _ref6;
            const { isFirstDrawer, object } = payload;
            if (isFirstDrawer) {
                state.firstObject = {
                    ...state.firstObject,
                    ...object
                };
            }
            else {
                state.secondObject = {
                    ...state.secondObject,
                    ...object
                };
            }
        },
        resetObject: (state, _ref7) => {
            let { payload } = _ref7;
            if (payload.isFirstDrawer) {
                state.firstObject = state.map[state.firstObject.id];
            }
            else {
                state.secondObject = state.map[state.secondObject.id];
            }
        },
        closeDrawer: (state, _ref8) => {
            let { payload } = _ref8;
            if (payload.isFirstDrawer) {
                if (isNewObject(state.firstObject)) {
                    state.map = _.omit(state.map, state.firstObject?.id);
                    state.allObjects = state.allObjects.filter(object => object.id !== state.firstObject?.id);
                }
                state.firstObject = undefined;
            }
            else {
                state.secondObject = undefined;
            }
        },
        setIsObjectSaveDisabled: (state, _ref9) => {
            let { payload: { isFirstDrawer, object } } = _ref9;
            const property = isFirstDrawer ? 'isFirstObjectSaveDisabled' : 'isSecondObjectSaveDisabled';
            if (!object) {
                state[property] = true;
                return;
            }
            state[property] = checkSavedDisabled(object, state.map[object.id]);
        },
        setFirstObject: (state, _ref10) => {
            let { payload: objectId } = _ref10;
            state.firstObject = state.map[objectId];
        },
        setSecondObject: (state, _ref11) => {
            let { payload: objectId } = _ref11;
            state.secondObject = state.map[objectId];
        },
        duplicateObject: (state, _ref12) => {
            let { payload: objectId } = _ref12;
            const selectedObject = state.map[objectId];
            const newObject = {
                ...selectedObject,
                id: NEW_OBJECT_ID,
                extId: selectedObject.extId + '-copy'
            };
            state.firstObject = newObject;
            state.map[newObject.id] = newObject;
            state.allObjects.unshift(newObject);
        },
        createUnsavedObject: (state, _ref13) => {
            let { payload } = _ref13;
            const newObject = createEmptyTEObject(payload.typeId, payload.fieldIds);
            state.firstObject = newObject;
            state.map[newObject.id] = newObject;
            state.allObjects.unshift(newObject);
        },
        setFindObjectPayload: (state, _ref14) => {
            let { payload } = _ref14;
            if (!_.isEqual(payload, state.findObjectPayload)) {
                state.findObjectPayload = payload;
            }
        }
    },
    selectors: {
        isFirstObjectSaveDisabledSelector: state => state.isFirstObjectSaveDisabled,
        isSecondObjectSaveDisabledSelector: state => state.isSecondObjectSaveDisabled
    }
});
const closeDrawerById = (state, objectId) => {
    if (state.firstObject?.id === objectId) {
        state.firstObject = undefined;
    }
    else {
        state.secondObject = undefined;
    }
};
export default slice.reducer;
// Selectors
export const objectsSelector = state => state.objects.allObjects;
export const objectMapSelector = state => state.objects.map;
export const firstObjectSelector = state => state.objects.firstObject;
export const secondObjectSelector = state => state.objects.secondObject;
export const findObjectPayloadSelector = state => state.objects.findObjectPayload;
// Actions
export const { fetchObjectsRequest, fetchObjectsSuccess, fetchObjectsFailure, deleteObjectRequest, deleteObjectSuccess, deleteObjectFailure, updateObjectRequest, updateObjectSuccess, updateObjectFailure, changeObject, resetObject, closeDrawer, setIsObjectSaveDisabled, setFirstObject, setSecondObject, duplicateObject, createUnsavedObject, setFindObjectPayload, fetchMoreObjectSuccess, fetchObjectsByIdsSuccess } = slice.actions;
export const { isFirstObjectSaveDisabledSelector, isSecondObjectSaveDisabledSelector } = slice.selectors;
export const findObjects = createAppAsyncThunk('objects/findObjects', async (body, _ref15) => {
    let { dispatch } = _ref15;
    try {
        dispatch(fetchObjectsRequest());
        const objects = await findMoreObjects(body);
        dispatch(fetchObjectsSuccess(objects));
        return objects;
    }
    catch (e) {
        dispatch(fetchObjectsFailure());
    }
});
export const findObjectsByIds = createAppAsyncThunk('objects/findObjects', async (_ref16, _ref17) => {
    let { ids } = _ref16;
    let { dispatch } = _ref17;
    try {
        dispatch(fetchObjectsRequest());
        const chunkObjectIds = _.chunk(ids, 1000);
        for (const chunk of chunkObjectIds) {
            const objects = await findMoreObjects({
                ids: chunk
            });
            dispatch(fetchObjectsByIdsSuccess(objects));
        }
    }
    catch (e) {
        dispatch(fetchObjectsFailure());
    }
});
export const findMoreObjects = async (body) => {
    try {
        return await api.post({
            endpoint: '/objects/find',
            data: body,
            suppressNotification: true
        });
    }
    catch (err) {
        console.error(err);
        return {
            results: [],
            page: 1,
            totalPages: 1,
            totalResults: 0,
            limit: 0
        };
    }
};
export const deleteObject = createAppAsyncThunk('objects/deleteObject', async (objectId, _ref18) => {
    let { dispatch } = _ref18;
    try {
        dispatch(deleteObjectRequest());
        await api.delete({
            endpoint: `/objects/${objectId}`
        });
        dispatch(deleteObjectSuccess(objectId));
    }
    catch (e) {
        dispatch(deleteObjectFailure());
        return console.error(e);
    }
});
export const updateObject = createAppAsyncThunk('objects/updateObject', async (object, _ref19) => {
    let { dispatch, getState } = _ref19;
    try {
        dispatch(updateObjectRequest());
        const { id, history, created, createdBy, virtual, abstract, color, ...objectBody } = object;
        if (id === NEW_OBJECT_ID) {
            await api.post({
                endpoint: `/objects`,
                data: objectBody
            });
        }
        else {
            await api.patch({
                endpoint: `/objects/${id}`,
                data: objectBody
            });
        }
        dispatch(updateObjectSuccess(id));
        const { objects } = getState();
        dispatch(findObjects(objects.findObjectPayload));
    }
    catch (e) {
        dispatch(updateObjectFailure());
        return console.error(e);
    }
});
export const checkFirstObjectExists = () => (_, getState) => !!getState().objects.firstObject;
export const checkObjectSaveDisabled = isFirstDrawer => (_, getState) => {
    const { objects } = getState();
    return isFirstDrawer ? objects.isFirstObjectSaveDisabled : objects.isSecondObjectSaveDisabled;
};
const selectedObjectPredicate = key => (_, currentState, previousState) => currentState.objects[key] !== previousState.objects[key];
const selectedObjectEffect = key => (_, _ref20) => {
    let { dispatch, getState } = _ref20;
    dispatch(setIsObjectSaveDisabled({
        isFirstDrawer: key === 'firstObject',
        object: getState().objects[key]
    }));
};
startAppListening({
    predicate: selectedObjectPredicate('firstObject'),
    effect: selectedObjectEffect('firstObject')
});
startAppListening({
    predicate: selectedObjectPredicate('secondObject'),
    effect: selectedObjectEffect('secondObject')
});
