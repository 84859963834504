import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { createEmptyField, EFieldType } from '@timeedit/types';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import _ from 'lodash';
import { getAllData } from 'utils/fetchAllData';
import { createAppAsyncThunk } from 'slices/utils';
const initialState = {
    loading: false,
    hasErrors: false,
    selectedField: null,
    selectedFieldCompare: null,
    allFields: [],
    errorMessage: '',
    typeIdToFieldsMap: {},
    advancedSettings: {},
    fieldMapping: {}
};
// Slice
const slice = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        fetchFieldsByTypeIdRequest: state => {
            isLoadingRequest(state);
        },
        fetchFieldsByTypeIdSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.typeIdToFieldsMap = {
                ...state.typeIdToFieldsMap,
                [payload.typeId]: payload.results
            };
            finishedLoadingSuccess(state);
        },
        fetchFieldsByTypeIdFailure: state => {
            finishedLoadingFailure(state);
        },
        fetchFieldsRequest: state => {
            isLoadingRequest(state);
        },
        fetchFieldsSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.allFields = payload.results;
            state.fieldMapping = _.keyBy(payload.results, 'id');
            finishedLoadingSuccess(state);
        },
        fetchFieldsFailure: state => {
            finishedLoadingFailure(state);
        },
        createFieldRequest: state => {
            isLoadingRequest(state);
        },
        createFieldSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.allFields = state.allFields.filter(field => !!field.id);
            state.allFields.unshift(payload);
            state.fieldMapping[payload.id] = payload;
            state.selectedField = undefined;
            finishedLoadingSuccess(state);
        },
        createUnsavedFieldSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            state.allFields = state.allFields.filter(field => !!field.id);
            state.allFields.unshift(payload);
            state.fieldMapping[payload.id] = payload;
            state.selectedField = payload;
            finishedLoadingSuccess(state);
        },
        createFieldFailure: state => {
            finishedLoadingFailure(state);
        },
        setCurrentField: (state, _ref5) => {
            let { payload } = _ref5;
            const previousField = state.selectedField;
            state.selectedField = payload;
            state.errorMessage = '';
            if (!payload && previousField.id === 0) {
                state.allFields = state.allFields.filter(field => !!field.id);
            }
        },
        setCurrentFieldCompare: (state, _ref6) => {
            let { payload } = _ref6;
            state.selectedFieldCompare = payload;
        },
        onDiscardForm: (state, _ref7) => {
            let { payload } = _ref7;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.selectedField = state.fieldMapping[id];
            }
            else {
                state.selectedFieldCompare = state.fieldMapping[id];
            }
        },
        deleteFieldRequest: state => {
            isLoadingRequest(state);
        },
        deleteFieldSuccess: (state, payload) => {
            state.allFields = state.allFields.filter(field => field.id !== payload.payload.id);
            state.fieldMapping[payload.payload.id] = undefined;
            finishedLoadingSuccess(state);
        },
        deleteFieldFailure: (state, _ref8) => {
            let { payload } = _ref8;
            state.errorMessage = payload;
            finishedLoadingFailure(state);
        },
        updateFieldRequest: state => {
            state.errorMessage = '';
            isLoadingRequest(state);
        },
        updateFieldSuccess: (state, _ref9) => {
            let { payload: { isFirstDrawer, field: updatedField } } = _ref9;
            state.errorMessage = '';
            const index = state.allFields.findIndex(field => field.id === updatedField.id);
            state.allFields[index] = updatedField;
            state.fieldMapping[updatedField.id] = updatedField;
            if (isFirstDrawer) {
                state.selectedField = undefined;
            }
            else {
                state.selectedFieldCompare = undefined;
            }
            finishedLoadingSuccess(state);
        },
        updateFieldFailure: (state, _ref10) => {
            let { payload } = _ref10;
            state.errorMessage = payload;
            finishedLoadingFailure(state);
        },
        fetchAdvancedSettingsFieldsSuccess: (state, _ref11) => {
            let { payload } = _ref11;
            const { fieldId, advancedSettings } = payload;
            state.advancedSettings[fieldId] = advancedSettings.results;
        }
    }
});
export default slice.reducer;
export const fieldsLoading = state => state.fields.loading;
export const fieldsSelector = state => state.fields.allFields;
export const selectFieldsOfType = type => state => {
    const allFields = state.fields.allFields;
    return allFields.filter(field => field.fieldType === type);
};
export const isNewFields = record => {
    return record.id === 0;
};
export const selectedFieldSelector = state => {
    return state.fields.selectedField;
};
export const selectedFieldCompareSelector = state => {
    return state.fields.selectedFieldCompare;
};
export const typeIdToFieldsMapSelector = state => state.fields.typeIdToFieldsMap;
export const advancedFieldsSelector = fieldsId => state => _.get(state, `fields.advancedSettings.${fieldsId}`, []);
export const fieldMappingSelector = state => state.fields.fieldMapping;
export const getListIdSelected = state => {
    const firstDrawer = state.fields.selectedField;
    const secondDrawer = state.fields.selectedFieldCompare;
    const result = [];
    if (firstDrawer)
        result.push(firstDrawer.id);
    if (secondDrawer)
        result.push(secondDrawer.id);
    return result;
};
export const { fetchFieldsRequest, fetchFieldsSuccess, fetchAdvancedSettingsFieldsSuccess, fetchFieldsFailure, createFieldRequest, createFieldSuccess, createFieldFailure, createUnsavedFieldSuccess, setCurrentField, setCurrentFieldCompare, deleteFieldRequest, deleteFieldSuccess, deleteFieldFailure, updateFieldRequest, updateFieldSuccess, updateFieldFailure, fetchFieldsByTypeIdRequest, fetchFieldsByTypeIdSuccess, fetchFieldsByTypeIdFailure, onDiscardForm } = slice.actions;
export const fetchFieldsByTypeId = createAppAsyncThunk('fields/fetchFieldsByTypeId', async (typeId, _ref12) => {
    let { dispatch } = _ref12;
    try {
        dispatch(fetchFieldsByTypeIdRequest());
        const fields = await api.post({
            endpoint: `/fields/find`,
            data: {
                typeId
            },
            suppressNotification: true
        });
        dispatch(fetchFieldsByTypeIdSuccess({
            ...fields,
            typeId
        }));
        return fields;
    }
    catch (e) {
        dispatch(fetchFieldsByTypeIdFailure());
    }
});
export const fetchFields = createAppAsyncThunk('fields/fetchFields', async (params, _ref13) => {
    let { dispatch } = _ref13;
    const { sort, fieldType, searchText, typeId } = params || {};
    try {
        dispatch(fetchFieldsRequest());
        const params = {
            ...(sort && {
                sort
            }),
            ...(fieldType && {
                fieldType
            }),
            ...(searchText && {
                searchText
            }),
            ...(typeId && {
                typeId
            })
        };
        const fields = await getAllData({
            endpoint: `/fields?${new URLSearchParams(params)}`
        }, 'get');
        dispatch(fetchFieldsSuccess(fields));
    }
    catch (e) {
        dispatch(fetchFieldsFailure());
        return console.error(e);
    }
});
export const createField = createAppAsyncThunk('fields/createField', async (field, _ref14) => {
    let { dispatch } = _ref14;
    try {
        dispatch(createFieldRequest());
        const { id, history, ...fieldBody } = field;
        const response = await api.post({
            endpoint: `/fields`,
            data: fieldBody
        });
        dispatch(createFieldSuccess(response));
        dispatch(fetchFields());
    }
    catch (e) {
        dispatch(createFieldFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const createUnsavedField = createAppAsyncThunk('fields/createUnsavedField', async (_, _ref15) => {
    let { dispatch } = _ref15;
    try {
        const response = {
            ...createEmptyField(),
            fieldType: EFieldType.TEXT
        };
        dispatch(createUnsavedFieldSuccess(response));
    }
    catch (e) {
        dispatch(createFieldFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const setSelectedField = field => async (dispatch) => {
    dispatch(setCurrentField(field));
};
export const copyField = createAppAsyncThunk('fields/copyField', async (field, _ref16) => {
    let { dispatch } = _ref16;
    try {
        const response = {
            ...field,
            id: 0,
            description: '',
            documentation: '',
            categories: [],
            referenceFields: [],
            checkSum: 0,
            name: `${field.name}-copy`,
            extId: `${field.extId}-copy`,
            history: []
        };
        dispatch(createUnsavedFieldSuccess(response));
    }
    catch (e) {
        dispatch(createFieldFailure());
        return console.error(e);
    }
});
export const deleteField = createAppAsyncThunk('fields/deleteField', async (field, _ref17) => {
    let { dispatch } = _ref17;
    try {
        dispatch(deleteFieldRequest());
        await api.delete({
            endpoint: `/fields/${field.id}`
        });
        dispatch(deleteFieldSuccess(field));
        return 1;
    }
    catch (e) {
        dispatch(deleteFieldFailure(e.response.data.message));
        console.error(e.response.data ? e.response.data : e.response);
        return 0;
    }
});
export const updateField = createAppAsyncThunk('fields/updateField', async (_ref18, _ref19) => {
    let { data: field, isFirstDrawer } = _ref18;
    let { dispatch } = _ref19;
    try {
        dispatch(updateFieldRequest());
        const { id, history, ...fieldBody } = field;
        const response = await api.patch({
            endpoint: `/fields/${field.id}`,
            data: fieldBody
        });
        dispatch(updateFieldSuccess({
            isFirstDrawer,
            field: response
        }));
    }
    catch (e) {
        dispatch(updateFieldFailure(e.response.data.message));
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const fetchAdvancedSettingsForField = createAppAsyncThunk('fields/fetchAdvancedSettingsForField', async (field, _ref20) => {
    let { dispatch } = _ref20;
    try {
        if (!isNewFields(field)) {
            const advancedSettings = await api.get({
                endpoint: `/fields/${field.id}/advanced`
            });
            dispatch(fetchAdvancedSettingsFieldsSuccess({
                fieldId: field.id,
                advancedSettings
            }));
        }
    }
    catch (e) {
        dispatch(fetchFieldsFailure());
        return console.error(e);
    }
});
export const checkFirstSaveDisabled = () => (_, getState) => {
    const state = getState();
    const firstDrawer = state.fields.selectedField;
    const fieldMap = state.fields.fieldMapping;
    return !firstDrawer || checkSavedDisabled(firstDrawer, fieldMap[firstDrawer?.id]);
};
export const checkSecondSaveDisabled = () => (_, getState) => {
    const state = getState();
    const secondDrawer = state.fields.selectedFieldCompare;
    const fieldMap = state.fields.fieldMapping;
    return !secondDrawer || checkSavedDisabled(secondDrawer, fieldMap[secondDrawer.id]);
};
export const getFirstDrawer = () => (_, getState) => {
    return getState().fields.selectedField;
};
